<template>
    <section>
        <h1>Add the Word</h1>
        <h2>Title</h2>
        <input type="text" v-model="title">
        <section>
            <h2>Type</h2>
            <section>
                <input type="text" v-model="newType" />
                <button @click="addWordType">add</button>
            </section>
            <select v-model="word_type">
                <option v-for="type in types" :key="type.id" :value="type.id">{{ type.name }}</option>
            </select>
        </section>
        <h2>Body</h2>
        <Vueditor ref="body"></Vueditor>
        <button @click="CreateWord">Create</button>
    </section>
</template>

<script>
 import axios from 'axios';
 import { uuid } from 'vue-uuid';

 export default {
  name: "UploadWord",
  data() {
   return {
    id: null,
    title: null,
    types: null,
    word_type: null,
    newType: null
   }
  },
  mounted () {
   this.getWordTypes();
   this.id = this.$route.query.id;
   if(this.$route.query.id === null || typeof this.$route.query.id === 'undefined'){
     this.id = uuid.v4()    
   }
  },
  methods: {
   getWordTypes () {
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/word/types',
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => (this.types = response.data.word_types))
      .catch((error) => {
       if(error.response.status === 400) {
        this.$router.push({ name: "catalog"})
       }
      })
   },
   addWordType () {
    let self = this;
    if(this.newType == null){
     return;
    }
    axios
      .post(process.env.VUE_APP_HOST_ADRES + '/word/type',
        {
         name: self.newType
        },
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
       .then(this.getWordTypes())
   },
   CreateWord(){
    let self = this;
    if(this.title == null || this.$refs.body.getContent() === "" || this.word_type == null){
     return;
    }
    axios
      .post(process.env.VUE_APP_HOST_ADRES + '/word',
        {
         id: self.id,
         title: self.title,
         word_body: self.$refs.body.getContent(),
         type: self.word_type
        },
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then()
   }
  },
 }
</script>

<style scoped>

</style>