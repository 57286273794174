<template>
  <section>
    <select v-model="selectedGroup">
      <option v-for="group in groups" v-bind:key="group.id" :value="group.id">{{ group.name }}</option>
    </select>
    <section v-for="link in getFilteredList()" class="flex gap-2" v-bind:key="link.generatedKey">
      <p>{{ link.name }} - {{ getResetLink(link.id) }}</p>
      <button @click="confirmDelete(link.user_id)">Delete</button>
    </section>
    <button @click="CreateResetForAllAccounts">Create Reset For all</button>
  </section>
</template>

<script>
import axios from 'axios'

export default {
  name: "new-password",
  data() {
    return {
      resetPasswords: null,
      groups: null,
      selectedGroup: null
    }
  },
  created() {
    this.getGroup()
    this.GetResetPasswordLinks()
  },
  methods: {
    getFilteredList() {
      if (this.selectedGroup === null) {
        return this.resetPasswords
      }

      return this.resetPasswords.filter((resetPassword) => {
        return resetPassword.usergroup === this.selectedGroup
      })
    },
    getGroup() {
      axios
          .get(process.env.VUE_APP_HOST_ADRES + '/groups', {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('user-token'),
            }
          })
          .then(response => (this.groups = response.data))
    },
    CreateResetForAllAccounts() {
      axios
          .post(process.env.VUE_APP_HOST_ADRES + '/new-password/all', {}, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('user-token'),
            }
          })
    },
    GetResetPasswordLinks() {
      axios
          .get(process.env.VUE_APP_HOST_ADRES + '/new-passwords', {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('user-token'),
            }
          })
          .then(response => (this.resetPasswords = response.data))
    },
    getResetLink(id) {
      return 'https://' + location.host + this.$router.resolve({
        name: 'reset-password',
        params: { id: id },
      }).href;
    },
    confirmDelete(id) {
      if (window.confirm('Are you sure you want to delete this account?')) {
        this.deleteAccount(id);
      }
    },
    deleteAccount(id) {
      axios
          .delete(`${process.env.VUE_APP_HOST_ADRES}/user/${id}`, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('user-token'),
            }
          })
          .then(response => {
            this.resetPasswords = this.resetPasswords.filter(link => link.user_id !== id);
            console.log(response.data);
          })
          .catch(error => console.error(error));
    }
  }
}
</script>

<style scoped>
/* Add your styles here */
</style>