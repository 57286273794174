import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import VueRouter from 'vue-router';
import Login from './components/Login';
import Education from './components/Education/Education';
import Catalog from './components/Education/Catalog';
import Category from './components/Education/Category';
import WebVideo from './components/Education/WebVideo';
import Upload from './components/Admin/Upload';
import Users from './components/Admin/users';
import UploadWord from './components/Admin/UploadWord';
import WrittenWordType from './components/Education/WrittenWordType';
import WrittenWord from './components/Education/WrittenWord';
import Views from './components/Admin/views';
import ResetPassword from './components/resetPassword';
import NewPassword from './components/Admin/NewPassword';
import Admin from './components/Admin/admin'
import Logins from './components/Admin/logins'
import VideosAdmin from './components/Admin/videos'
import WordsAdmin from './components/Admin/word'
import Daily from './components/Admin/daily'
import WordVideo from './components/Admin/wordVideo'
import LoggedIn from './components/Admin/loggedin'
import Audios from './components/Admin/audios'
import WebAudio from './components/Education/WebAudio'


import { library } from '@fortawesome/fontawesome-svg-core'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faEye)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

Vue.use(VueRouter);

const routes = [
 {
  path: '/',
  redirect: 'login'
 },
 {
  path: '/login',
  name: 'login',
  component: Login,
 },
 {
  path: '/reset-password/:id',
  name: 'reset-password',
  component: ResetPassword,
 },
 {
  path: '/admin',
  component: Admin,
  children: [
   {
    path: 'new-password',
    name: 'new-password',
    component: NewPassword,
   },
   {
    path: 'users',
    name: 'users',
    component: Users,
   },
   {
    path: 'logins',
    name: 'logins',
    component: Logins,
   },
   {
    path: 'views',
    name: 'views',
    component: Views
   },
   {
    path: 'videos',
    name: 'videos',
    component: VideosAdmin
   },
   {
    path: 'words',
    name: 'words',
    component: WordsAdmin
   },
   {
    path: 'upload',
    name: 'upload',
    component: Upload,
   },
   {
    path: 'upload/word',
    name: 'UploadWord',
    component: UploadWord
   },
   {
    path: 'daily',
    name: 'daily',
    component: Daily
   },
   {
    path: 'wordVideo',
    name: 'wordVideo',
    component: WordVideo
   },
   {
    path: 'logged-in',
    name: 'loggedIn',
    component: LoggedIn
   },
   {
    path: 'audios',
    name: 'audios',
    component: Audios
   },
  ]
 },
 {
  path: '/education',
  component: Education,
  children: [
   {
    path: 'catalog',
    name: 'catalog',
    component: Catalog
   },
   {
    path: 'category/:id',
    name: 'category',
    component: Category
   },
   {
    path: 'word/:id',
    name: 'word',
    component: WrittenWord
   },
   {
    path: 'word/type/:id',
    name: 'wordType',
    component: WrittenWordType
   },
   {
    path: 'video/:id',
    name: 'video',
    component: WebVideo
   },
   {
    path: 'audio/:id',
    name: 'audio',
    component: WebAudio
   },
  ]
 }
];

const router = new VueRouter({
 routes
});

import Vueditor from 'vueditor'

import 'vueditor/dist/style/vueditor.min.css'

// your config here
let config = {
 toolbar: [
  'removeFormat', 'undo', '|', 'elements', 'fontName', 'fontSize', 'foreColor', 'backColor'
 ],
 fontName: [
  {val: 'arial black'},
  {val: 'times new roman'},
  {val: 'Courier New'}
 ],
 fontSize: ['12px', '14px', '16px', '18px', '0.8rem', '1.0rem', '1.2rem', '1.5rem', '2.0rem'],
 uploadUrl: ''
};

Vue.use(Vuex);
Vue.use(Vueditor, config);

new Vue({
 render: h => h(App),
 router
}).$mount('#app');


