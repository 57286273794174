<template>
    <section>
        <section v-for="video in videos" v-bind:key="video.id">
            <input type="text" :value="video.name" v-on:input="changeVideoName($event, video.id)">
            <input type="date" :value="getSQLDateToVueInputDate(video.created_at)" @change="changeVideoDate($event, video.id, video.created_at)">
            <select v-model="video.category" @change="changeVideoCategory($event, video.id)">
                <option v-for="category in categories" v-bind:key="category.id" :value="category.id">{{category.name}}</option>
            </select>
            <button @click="deleteVideo(video.id)">X</button>
        </section>
    </section>
</template>

<script>
 import axios from 'axios'

 export default {
  name: "videos",
  data() {
   return {
    videos: null,
    categories: null
   }
  },
  created() {
   this.getVideos()
   this.getCategories()
  },
  methods: {
   deleteVideo(id) {
    axios.post(process.env.VUE_APP_HOST_ADRES + '/video/delete',
      {
       id
      },
      {
       headers: {
        Authorization: 'Bearer ' + localStorage.getItem('user-token'),
       }
      })
      .then(setTimeout(this.getVideos(), 100))
   },
   changeVideoName (event, id) {
    axios.post(process.env.VUE_APP_HOST_ADRES + '/videos/name',
      {
        id,
        name: event.target.value
      },
      {
       headers: {
        Authorization: 'Bearer ' + localStorage.getItem('user-token'),
       }
      })
   },
   changeVideoDate (event, id, datetime) {
    const date = event.target.value + ' ' + datetime.split('T')[1].split('.')[0]
    axios.post(process.env.VUE_APP_HOST_ADRES + '/videos/date',
      {
       id,
       createdAt: date
      },
      {
       headers: {
        Authorization: 'Bearer ' + localStorage.getItem('user-token'),
       }
      })
   },
   changeVideoCategory (event, id) {
    axios.post(process.env.VUE_APP_HOST_ADRES + '/videos/category',
      {
       id,
       category: event.target.value
      },
      {
       headers: {
        Authorization: 'Bearer ' + localStorage.getItem('user-token'),
       }
      })
   },
   getVideos() {
    axios.get(process.env.VUE_APP_HOST_ADRES + '/videos',
      {
       headers: {
        Authorization: 'Bearer ' + localStorage.getItem('user-token'),
       }
      })
      .then(response => (this.videos = response.data))
   },
   getSQLDateToVueInputDate(video_date){
    return video_date.split('T')[0]
   },
   getCategories: function () {
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/categories',
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => (this.categories = response.data))
   },
  }
 }
</script>

<style scoped>

</style>