<template>
    <section>
        <h1>Logged in members</h1>
        <section v-for="(department, indexLogged) in departments" v-bind:key="indexLogged">
            <b>{{ department }}</b>
            <p v-for="(member, indexM) in getDeptLogged(department)" v-bind:key="indexM">{{indexM + 1}}. {{member.name}}</p>
        </section>
        <h1>Not logged in members</h1>
        <section v-for="(department, indexNotLogged) in departments" v-bind:key="indexNotLogged">
            <b>{{ department }}</b>
            <p v-for="(member, indexM) in getNotDeptLogged(department)" v-bind:key="indexM">{{indexM + 1}}. {{member.name}}</p>
        </section>
    </section>
</template>

<script>
 import axios from 'axios'
 export default {
  name: "loggedin",
  data() {
   return {
    departments: ['Men', 'Women', 'Youth'],
    loggedIn: null,
    notLoggedIn: null
   }
  },
  mounted(){
   this.getLoggedIn()
   this.getNotLoggedIn()
  },
  methods: {
   getDeptLogged(department) {
    if(this.loggedIn === null){
     return
    }

    return this.loggedIn.filter((item) => {
        if(item.department === department){
         return item
        }
    })
   },
   getNotDeptLogged(department) {
    if(this.notLoggedIn === null){
     return
    }

    return this.notLoggedIn.filter((item) => {
     if(item.department === department){
      return item
     }
    })
   },
   getLoggedIn() {
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/logged-in',
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => {
       this.loggedIn = response.data
      })
   },
   getNotLoggedIn() {
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/not-logged-in',
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => {
       this.notLoggedIn = response.data
      })
   }
  }
 }
</script>

<style scoped>

</style>