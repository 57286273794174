<template>
    <section>
        <section v-for="wordVideo in wordVideos" v-bind:key="wordVideo.id">
            <select v-model="wordVideo.word_id" @change="changeWord($event, wordVideo.id)">
                <option v-for="word in words" v-bind:key="word.id" :value="word.id">{{word.title}}</option>
            </select>
            <select v-model="wordVideo.video_id" @change="changeVideo($event, wordVideo.id)">
                <option v-for="video in videos" v-bind:key="video.id" :value="video.id">{{video.name}}</option>
            </select>
        </section>
        <button @click="addNewWordVideo()">+</button>
    </section>
</template>

 <script>
 import axios from 'axios'
 export default {
  name: "word-video",
  data() {
   return {
    words: null,
    videos: null,
    wordVideos: null
   }
  },
  created () {
   this.getWords();
   this.getVideos();
   this.getWordVideos();
  },
  methods: {
   getWords(){
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/words',
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => (this.words = response.data.words))
   },
   getVideos(){
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/videos',
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => (this.videos = response.data))
   },
   getWordVideos(){
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/word-video',
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => (this.wordVideos = response.data))
   },
   addNewWordVideo(){
    const self = this;
    axios
      .post(process.env.VUE_APP_HOST_ADRES + '/word-video/new',
        {

        },
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(setTimeout(
        function(){
         self.getWordVideos()
        }, 1000)
      )
   },
   changeWord(event, id){
    const self = this;
    axios
      .post(process.env.VUE_APP_HOST_ADRES + '/word-video/word',
        {
         id,
         word_id: event.target.value
        },
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(setTimeout(
        function(){
         self.getWordVideos()
        }, 1000)
      )
   },
   changeVideo(event, id){
    const self = this;
    axios
      .post(process.env.VUE_APP_HOST_ADRES + '/word-video/video',
        {
         id,
         video_id: event.target.value
        },
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(setTimeout(
        function(){
         self.getWordVideos()
        }, 1000)
      )
   }
  }
 }
</script>

<style scoped>

</style>