<template>
    <section>
        <h1>{{category_name}}</h1>
        <section v-for="media in medias" :key="media.key">
            <router-link :to="{ name: media.media_type, params: { id: media.id }}">
                <section class="media list" >
                    <img v-if="media.media_type == 'video'" :src="getVideoImage(media.id)"/>
                    <img v-if="media.media_type == 'audio'" :style="{width: 50 + 'px'}" src="@/assets/sound.png"/>
                    <h2>{{media.name}}</h2>
                    <font-awesome-icon v-if="isView(media.id)" :icon="['fas', 'eye']"  />
                </section>
            </router-link>
        </section>
    </section>
</template>

<script>
 import axios from 'axios';

 export default {
  name: "category",
  data() {
   return {
    category_name: null,
    categories: null,
    medias: null,
    views: null
   }
  },
  mounted() {
   this.category = this.$route.params.id;
   this.getAccess(this.category);
   this.getCategory(this.category);
   this.getVideos(this.category);
   this.getViews();
  },
  methods: {
   isView(media_id){
    if(this.views === null){
     return;
    }


    const views = this.views.map(function (view){
     if(view.media_id === media_id){
      return true;
     }
    });

    return views.includes(true) || false;
   },
   getAccess(category) {
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/categories/' + category + '/access',
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => {
       if(response.data === false){
        this.$router.push({name: 'catalog'})
       }
      })
   },
   getCategory: function (category) {
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/category/' + category,
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => (this.category_name = response.data[0].name))
   },
   getCategories: function () {
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/categories/user',
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => (this.categories = response.data))
   },
   getVideos: function (category) {
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/category/' + category + '/media',
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => (this.medias = response.data))
   },
   getVideoImage: function (video_id) {
    return process.env.VUE_APP_HOST_ADRES + '/video/thumbnail/' + video_id
   },
   getViews: function () {
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/views/user',
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => (this.views = response.data))
   }
  }
 }
</script>

<style scoped lang="scss">
    h1 {
        text-align: left;
    }

    h1 {
        font-size: 24px;
    }
    p {
        font-size: 16px;
    }

    .list {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            align-self: center;
            justify-self: center;
        }
        h2 {
            font-size: 11px;
            text-align: left;
            margin-left: 20px;
        }
        svg {
            justify-self: center;
            align-self: center;
        }
        display: grid;
        grid-template-columns: 1fr 7fr 1fr;
    }

    a {
        .media {
            color: #2c3e50;

            transition: 0.1s all ease;
        }
        overflow: auto;
        height: 50px;
        background: #eeeeee;
        border-bottom: 1px solid #838383;
        text-decoration: none;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }


    a:hover {
        background: #59b698;
    }

    a:last-child {
        border-bottom: none !important;
    }
</style>