<template>
    <section>
        <section ref="daily">
            <b>Logins of the Day</b>
            <p v-for="(login, index) in logins" v-bind:key="index">{{index +1}}. {{login.name}}</p>
            <b>Daily Views Per Department</b>
            <p v-for="department in departments" v-bind:key="department.id">{{department.name}}:
                    {{department.count}}/{{getTotalDepartmentCount(department.id)}}</p>
            <b>Daily Views Per Categories</b>
            <p v-for="category in categories" v-bind:key="category.id">{{category.name}}:
                {{category.count}}</p>
        </section>
    </section>
</template>

<script>
 import axios from 'axios'

 export default {
  name: "daily",
  data() {
   return {
    logins: null,
    departments: null,
    categories: null,
    totalDepartments: null,
    totalCategories: null,
   }
  },
  mounted() {
   this.getLogins()
   this.getDailyDepartment()
   this.getDailyCategory()
   this.getTotalDepartments()
   this.getTotalCategories()
   document.addEventListener("keydown", this.copyDaily);
  },
  beforeDestroy() {
   document.removeEventListener("keydown", this.copyDaily);
  },
  methods: {
   getTotalDepartmentCount(id) {
    if (id === null) {
     return;
    }

    if (this.totalDepartments === null) {
     return;
    }

    const deptTotal = this.totalDepartments.filter((item) => {
     if (item.id === id) {
      return item
     }
    })

    return deptTotal[0].count
   },
   getTotalCategoryCount(id) {
    if (id === null) {
     return;
    }

    if (this.totalCategories === null) {
     return;
    }

    const categoryTotal = this.totalCategories.filter((item) => {
     if (item.id === id) {
      return item
     }
    })

    return categoryTotal[0].count
   },
   getLogins() {
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/logins/daily',
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => {
       this.logins = response.data
      })
   },
   getDailyDepartment() {
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/daily/views/department',
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => {
       this.departments = response.data
      })
   },
   getDailyCategory() {
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/daily/views/category',
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => {
       this.categories = response.data
      })
   },
   getTotalDepartments() {
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/usergroups/count',
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => {
       this.totalDepartments = response.data
      })
   },
   getTotalCategories() {
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/categories/count',
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => {
       this.totalCategories = response.data
      })
   },
  }
 }
</script>

<style scoped>

</style>