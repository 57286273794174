<template>
    <section>
        <section class="login" v-for="login in logins" v-bind:key="login.id">
            <p>{{login.name}}</p>
            <p>{{new Date(login.created_at).getFullYear()}}-{{('0' + new Date(login.created_at).getMonth() + 1).slice(-2)}}-{{new Date(login.created_at).getDate()}}
                {{new Date(login.created_at).getHours()}}:{{('0' + new Date(login.created_at).getMinutes()).slice(-2) }}</p>
        </section>
    </section>
</template>

<script>
 import axios from 'axios'

 export default {
  name: "logins",
  data() {
   return {
    logins: null
   }
  },
  created() {
   this.getLogins()
  },
  methods: {
   getLogins: function () {
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/logins',
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => (this.logins = response.data))
   },
  }
 }
</script>

<style scoped lang="scss">
    .login {
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: 50px;
    }
    .login:nth-child(odd){
        background: #5dcda9;
    }
    .login:nth-child(even){
        background: #75eec7;
    }
</style>