<template>
    <section>
        <h1>Users</h1>
        <section>
            <h2>Create user</h2>
            <section class="input-grid">
                <h2>Name</h2>
                <input v-model="name"  v-on:keyup.enter="login" type="text"/>
                <div></div>
            </section>
            <section class="input-grid">
                <h2>Email</h2>
                <input v-model="email"  v-on:keyup.enter="login" type="text"/>
                <div></div>
            </section>
            <button v-on:click="createUser">Create User</button>
        </section>
        <section>
            <h2>Create Users with list</h2>
            <textarea v-model="userList">
            </textarea>
            <button @click="CreateUserWithList">Create Users</button>
        </section>
    </section>
</template>

<script>
    import axios from 'axios';

    export default {
        name: "Users",
        data(){
            return {
                email: null,
                name: null,
                userList: null
            }
        },
        methods: {
         createUser(){
                if(this.email === null){
                    return;
                }

                if(this.name === null){
                    return;
                }

                axios
                    .post(process.env.VUE_APP_HOST_ADRES + '/user', {
                        email: this.email,
                        name: this.name
                    },
                    {
                        headers: {
                            Authorization: 'Bearer ' + localStorage.getItem('user-token'),
                        }
                    })
                    .then(response => {
                        console.log(response)
                    })
            },
         CreateUserWithList() {
          if(this.userList === null){
           return;
          }

          let self = this
          axios
            .post(process.env.VUE_APP_HOST_ADRES + '/users', {
               userList: self.userList
              },
              {
               headers: {
                Authorization: 'Bearer ' + localStorage.getItem('user-token'),
               }
              })
            .then(response => {
             console.log(response)
            })
         }
        },
    }
</script>

<style scoped lang="scss">
</style>