<template>
    <section>
        <h1>{{category_name}}</h1>
        <section>
            <router-link v-for="word in words" :key="word.id" :to="{ name: 'word', params: { id: word.id }}">
                <section class="word list">
                    <img src="@/assets/bible.png" />
                    <h2>{{word.title}} - {{getDateInYYYYMMDD(word.created_at)}}</h2>
                </section>
            </router-link>
        </section>
    </section>
</template>

<script>
 import axios from 'axios';

 export default {
  name: "written-word",
  data() {
   return {
    category_name: null,
    words: null,
   }
  },
  mounted() {
   this.category = this.$route.params.id;
   this.getCategory(this.category);
   this.getWord(this.category);
  },
  methods: {
   getDateInYYYYMMDD(date){
    const scjDate = date.split('T')[0];
    const scjYear = date.split('-')[0] - 1984 + 1;
    return scjYear + '.' + scjDate.split('-')[1] + '.' + scjDate.split('-')[2]
   },
   getCategory: function (category) {
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/word/type/' + category,
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => (this.category_name = response.data[0].name))
   },
   getWord: function (category) {
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/word/type/search/' + category,
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => (this.words = response.data))
   },
  }
 }
</script>

<style scoped lang="scss">
    h1 {
        text-align: left;
    }

    .list {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            align-self: center;
        }
        h2 {
            font-size: 11px;
            text-align: left;
            margin-left: 20px;
        }
        display: grid;
        grid-template-columns: 1fr 7fr;
    }

    a {
        .word {
            color: #2c3e50;
            padding: 1px;
            height: 50px;
            transition: 0.1s all ease;
        }
        .word:hover {
            background: #59b698;
        }
        overflow: auto;
        background: #eeeeee;
        border-bottom: 1px solid #838383;
        text-decoration: none;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
    a:last-child {
        border-bottom: none !important;
    }

    @media only screen and (max-width: 600px) {
        h1 {
            font-size: 40px;
        }
        p {
            font-size: 17px;
        }
    }

</style>