<template>
    <section>
        <h1>Education Materials</h1>
        <section class="video-categories">
            <router-link class="category" v-for="item in catalog" :key="getId(item)"
                         :to="{ name: getRoute(item), params: { id: getId(item) }}">
                <section class="video-link list">
                    <img :src="getImage(item)" />
                    <h2>{{item.name}}</h2>
                </section>
            </router-link>
        </section>
        <button class="searchButton" @click="activeSpotlight">Search: CTRL k / ⌘ k</button>
    </section>
</template>

<script>
 import axios from 'axios';

 export default {
  name: "catalog",
  data() {
   return {
    catalog: null
   }
  },
  mounted() {
   this.getCatalog();
  },
  methods: {
   activeSpotlight (){
    this.$parent.activeSpotlight();
   },
   getCatalog: function () {
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/catalog',
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => (this.catalog = response.data))
   },
   getCategoryImage: function (category_id) {
    return process.env.VUE_APP_HOST_ADRES + '/category/image/' + category_id
   },
   getWordImage: function (type_id) {
    return process.env.VUE_APP_HOST_ADRES + '/word/type/image/' + type_id
   },
   getRoute: function(item){
    if(item.category){
     return "category"
    }
    return "wordType"
   },
   getImage: function (item){
    if(item.category){
     return this.getCategoryImage(item.category)
    }
    return this.getWordImage(item.word)
   },
   getId: function (item){
    if(item.category){
     return item.category
    }
    return item.word
   }
  }
 }
</script>

<style scoped lang="scss">
    h1 {
        text-align: left;
        font-size: 24px;
    }

    .list {
        img {
            width: 100%;
            height: inherit;
            object-fit: cover;
            align-self: center;
        }
        h2 {
            font-size: 11px;
            text-align: left;
            margin-left: 20px;
        }
        display: grid;
        grid-template-columns: 1fr 7fr;
    }

    .video-categories {
        display: grid;
        grid-template-columns: 1fr;
        align-items: center;
        column-gap: 30px;
        width: 100%;
    }

    a {
        .video-link {
            h2 {
                align-self: center;
            }
            height: 50px;
            color: #2c3e50;

        }
        overflow: hidden;
        background: #eeeeee;
        text-decoration: none;
        -webkit-transition: all 0.2s;
        -moz-transition: all 0.2s;
        -ms-transition: all 0.2s;
        -o-transition: all 0.2s;
        transition: all 0.2s;
    }

    .searchButton {
        margin-top: 20px;
        margin-left: auto;
        display: block;
        background: #59b698;
        color: #ffffff;
        border: none;
        padding: 10px 20px;
    }

    a:hover {
        background: #59b698;
    }

    .category {
        border-bottom: 1px solid #838383;
    }

    a:last-child {
        border-bottom: none !important;
    }
</style>