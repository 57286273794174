<template>
    <section class="audio">
        <div></div>
        <section>
            <section class="title">
                <h1>{{audio_name}}</h1>
                <font-awesome-icon v-if="isView(audio_id)" :icon="['fas', 'eye']"  />
            </section>
            <section class="audio-container">
                <audio
                        ref="audio"
                        class="audio-view"
                        v-if="audio_src"
                        oncontextmenu="return false;"
                        @play="startPlay"
                        @timeupdate="updateProcent"
                        controls
                        controlsList="nodownload"
                >
                    <source :src="audio_src" type="audio/mpeg">
                </audio>
                <vue-slider
                        v-model="speed"
                        v-bind="speedOptions"
                        @change="changeSpeed"
                />
            </section>
            <p v-if="body" v-html="body"></p>
        </section>
        <div></div>
    </section>
</template>

<script>
 import axios from 'axios'
 import VueSlider from 'vue-slider-component'
 import 'vue-slider-component/theme/antd.css'

 export default {
  name: "web-audio",
  components: {
   VueSlider
  },
  data() {
   return {
    speedOptions: {
     min: 0.5,
     max: 2.5,
     width: 100,
     interval: 0.1
    },
    speed: 1,
    audio_id: null,
    audio_src: null,
    audio_filename: null,
    audio_name: null,
    token: null,
    view_send: false,
    total_watch_time: 0,
    audio_time: 0,
    view_id: null,
    procent_watched: 0,
    view_procent_watched: 0,
    polling: null,
    body: null,
    views: null
   }
  },
  async mounted() {
   this.audio_id = this.$route.params.id;
   await this.getAccess(this.audio_id);
   await this.getUrl(this.audio_id);
   await this.findOrCreateView(this.audio_id);
   await this.getViews();
  },
  created() {
   this.pollData()
  },
  beforeDestroy() {
   clearInterval(this.polling)
  },
  methods: {
   changeSpeed(){
    this.$refs.audio.playbackRate = this.speed;
   },
   isView(media_id){
    if(this.views === null){
     return;
    }

    const views = this.views.map(function (view){
     if(view.media_id === media_id){
      return true;
     }
    });

    return views.includes(true) || false;
   },
   pollData() {
    this.polling = setInterval(() => {
     this.sendProcent()
     this.sendView()
    }, 3000)
   },
   getStreamLink(token, filename) {
    this.audio_src = process.env.VUE_APP_HOST_ADRES + "/audio/stream/" + token + '/' + filename;
   },
   getAccess(audioId) {
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/audio/' + audioId + '/access',
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => {
       if (response.data === false) {
        this.$router.push({name: 'catalog'})
       }
      })
   },
   startPlay(event) {
    this.audio_time = event.target.currentTime
   },
   sendView() {
    if (
      this.getCombinedProcent() < 60 ||
      this.view_send === true
    ) {
     return;
    }
    this.setAudioToWatched()
    this.view_send = true
   },
   setAudioToWatched() {
    const self = this;
    axios
      .post(
        process.env.VUE_APP_HOST_ADRES + '/view/watched',
        {
         view_id: self.view_id,
        },
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
   },
   updateProcent(event) {
    if ((event.target.currentTime - this.audio_time) > 10) {
     return
    }

    if ((event.target.currentTime - this.audio_time) < 0.5) {
     return
    }

    this.total_watch_time += event.target.currentTime - this.audio_time;
    this.audio_time = event.target.currentTime;
    this.procent_watched = this.total_watch_time / event.target.duration * 100;
   },
   getCombinedProcent() {
    if (typeof this.view_procent_watched === 'undefined') {
     return this.procent_watched
    }
    return this.view_procent_watched + this.procent_watched
   },
   sendProcent() {
    if (this.getCombinedProcent() > 100) {
     return;
    }

    const view = {
     view_id: this.view_id,
     procent_watched: this.getCombinedProcent()
    }

    axios
      .post(
        process.env.VUE_APP_HOST_ADRES + '/view/update/time',
        view,
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
   },
   getUrl(audioId) {
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/audio/' + audioId,
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => {
       this.audio_filename = response.data.audios[0].filename;
       this.audio_name = response.data.audios[0].name;
       this.body = response.data.audios[0].body;
       this.token = response.data.streamToken
       this.getStreamLink(this.token, this.audio_filename);
      })
   },
   getViews: function () {
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/views/user',
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => (this.views = response.data))
   },
   findOrCreateView(audio_id) {
    axios
      .post(
        process.env.VUE_APP_HOST_ADRES + '/view/new',
        {
         'media_id': audio_id
        },
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => {
       this.view_id = response.data.id
       this.view_procent_watched = response.data.procent_watched
      })
   },
  }
 }
</script>

<style scoped lang="scss">
    h1 {
        font-size: 24px;
    }

    h1, p {
        text-align: left;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, */
    }

    p {
        white-space: pre-wrap
    }

    .audio {
        .title {
            svg {
                align-self: center;
            }
            display: grid;
            grid-template-columns: 4fr 1fr;
        }
        display: grid;
        grid-template-columns: 1fr 3fr 1fr;
    }

    @media only screen and (max-width: 600px) {
        .audio {
            display: block;
        }
    }
</style>