<template>
    <section>
        <nav>
            <section>
                <router-link :to="{ name: 'catalog'}" class="button-styling">Catalog</router-link>
                <router-link :to="{ name: 'new-password'}" class="button-styling">New passwords</router-link>
                <router-link :to="{ name: 'users'}" class="button-styling">Users</router-link>
                <router-link :to="{ name: 'logins'}" class="button-styling">Logins</router-link>
                <router-link :to="{ name: 'loggedIn'}" class="button-styling">Logged in</router-link>
                <router-link :to="{ name: 'views'}" class="button-styling">Views</router-link>
                <router-link :to="{ name: 'upload'}" class="button-styling">Upload</router-link>
                <router-link :to="{ name: 'UploadWord'}" class="button-styling">Word Upload</router-link>
                <router-link :to="{ name: 'daily'}" class="button-styling">Daily</router-link>
                <router-link :to="{ name: 'wordVideo'}" class="button-styling">Word-Video</router-link>
                <router-link :to="{ name: 'videos'}" class="button-styling">Videos</router-link>
                <router-link :to="{ name: 'words'}" class="button-styling">Words</router-link>
                <router-link :to="{ name: 'audios'}" class="button-styling">Audios</router-link>
            </section>
            <div></div>
            <button class="button-styling logout" v-on:click="logout">Logout</button>
        </nav>
        <router-view></router-view>
    </section>
</template>

<script>
 import axios from 'axios';
 export default {
  name: "admin",
  mounted(){
   this.authentication()
  },
  methods: {
   authentication: function(){
    axios
      .post(process.env.VUE_APP_HOST_ADRES + '/user/authenticate/check',
        {
         email: localStorage.getItem('email'),
        },
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        }
      )
      .then(response => {
         if (response.data === false) {
          this.$router.push({ name: "login"})
         }

         if (response.data.status === 403) {
          this.$router.push({ name: "login"})
         }
        }
      )
   },
   logout: function(){
    localStorage.removeItem('email');
    localStorage.removeItem('user-token');
    this.$router.push({ name: "login"})
   }
  }
 }
</script>

<style scoped>
    nav {
        display: grid;
        grid-template-columns: 3fr 3fr 1fr;
        margin-bottom: 25px;
    }
    .button-styling {
        float: left;
        padding: 10px;
        border: 2px solid #59b698;
        color: #2c3e50;
        text-decoration: none;
        display: block;
        cursor: pointer;
    }

    .logout {
        /*background: #8d9298;*/
    }

    button{
        border: none;
        width: 100%;
        cursor: pointer;
    }
</style>