<template>
    <section>
        <section>
            <h2>Upload Audio</h2>
            <div>Text field title:
                <input type="text" name="title" v-model="audio_name"/>
            </div>
            <div>File:
                <input type="file" ref="file" name="audio" v-on:change="handleAudioUpload()"/>
            </div>
            <div>
                Category
                <select v-model="category">
                    <option v-for="category in categories" :key="category.id" v-bind:value="category.id">
                        {{category.name}}
                    </option>
                </select>
            </div>
            <Vueditor ref="body"></Vueditor>
            <button v-on:click="onSubmitAudio">Upload</button>
            <p v-if="upload_progress !== 0 " :class="{green: upload_progress === 100}">{{ upload_progress }}</p>
        </section>
        <section v-for="audio in audios" v-bind:key="audio.id">
            <input type="text" :value="audio.name" v-on:input="changeAudioName($event, audio.id)">
            <input type="date" :value="getSQLDateToVueInputDate(audio.created_at)"
                   @change="changeAudioDate($event, audio.id, audio.created_at)">
            <select v-model="audio.category" @change="changeAudioCategory($event, audio.id)">
                <option v-for="category in categories" v-bind:key="category.id" :value="category.id">{{category.name}}
                </option>
            </select>
            <button @click="deleteAudio(audio.id)">X</button>
        </section>
    </section>
</template>

<script>
 import axios from 'axios'

 export default {
  name: "audios",
  data() {
   return {
    audios: null,
    categories: null,
    audio: null,
    audio_name: null,
    upload_progress: 0,
    category: null
   }
  },
  created() {
   this.getAudios()
   this.getCategories()
  },
  methods: {
   onSubmitAudio: function () {
    let formData = new FormData();
    formData.append('audio', this.audio);
    formData.set('category', this.category);
    formData.set('body', this.$refs.body.getContent());
    formData.set('name', this.audio_name);

    this.upload_progress = 0

    const request_config = {
     onUploadProgress: function (progressEvent) {
      self.upload_progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
     },
     headers: {
      "Content-Type": "multipart/form-data",
      "Authorization": 'Bearer ' + localStorage.getItem('user-token'),
     },
     data: formData
    };

    let self = this;

    axios
      .post(process.env.VUE_APP_HOST_ADRES + '/audio', formData, request_config)
   },
   handleAudioUpload: function () {
    this.audio = this.$refs.file.files[0];
   },
   deleteAudio(id) {
    axios.post(process.env.VUE_APP_HOST_ADRES + '/audio/delete',
      {
       id
      },
      {
       headers: {
        Authorization: 'Bearer ' + localStorage.getItem('user-token'),
       }
      })
      .then(setTimeout(this.getAudios(), 100))
   },
   changeAudioName(event, id) {
    axios.post(process.env.VUE_APP_HOST_ADRES + '/audios/name',
      {
       id,
       name: event.target.value
      },
      {
       headers: {
        Authorization: 'Bearer ' + localStorage.getItem('user-token'),
       }
      })
   },
   changeAudioDate(event, id, datetime) {
    const date = event.target.value + ' ' + datetime.split('T')[1].split('.')[0]
    axios.post(process.env.VUE_APP_HOST_ADRES + '/audios/date',
      {
       id,
       createdAt: date
      },
      {
       headers: {
        Authorization: 'Bearer ' + localStorage.getItem('user-token'),
       }
      })
   },
   changeAudioCategory(event, id) {
    axios.post(process.env.VUE_APP_HOST_ADRES + '/audios/category',
      {
       id,
       category: event.target.value
      },
      {
       headers: {
        Authorization: 'Bearer ' + localStorage.getItem('user-token'),
       }
      })
   },
   getAudios() {
    axios.get(process.env.VUE_APP_HOST_ADRES + '/audios',
      {
       headers: {
        Authorization: 'Bearer ' + localStorage.getItem('user-token'),
       }
      })
      .then(response => (this.audios = response.data))
   },
   getSQLDateToVueInputDate(audio_date) {
    return audio_date.split('T')[0]
   },
   getCategories: function () {
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/categories',
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => (this.categories = response.data))
   },
  }
 }
</script>

<style scoped>

</style>