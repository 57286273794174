<template>
    <section>
        <section v-for="word in words" v-bind:key="word.id">
            <input type="text" :value="word.title" v-on:input="changeWordTitle($event, word.id)">
            <input type="date" :value="getSQLDateToVueInputDate(word.created_at)"
                   @change="changeWordDate($event, word.id, word.created_at)">
            <select v-model="word.type" @change="changeWordType($event, word.id)">
                <option v-for="type in types" v-bind:key="type.id" :value="type.id">{{type.name}}</option>
            </select>
            <button @click="deleteWord(word.id)">X</button>
        </section>
    </section>
</template>

<script>
 import axios from 'axios'

 export default {
  name: "word",
  data() {
   return {
    words: null,
    types: null
   }
  },
  created() {
   this.getWords()
   this.getTypes()
  },
  methods: {
   deleteWord(id) {
    axios.post(process.env.VUE_APP_HOST_ADRES + '/word/delete',
      {
       id
      },
      {
       headers: {
        Authorization: 'Bearer ' + localStorage.getItem('user-token'),
       }
      })
      .then(this.getWords())
   },
   changeWordTitle(event, id) {
    axios.post(process.env.VUE_APP_HOST_ADRES + '/word/title',
      {
       id,
       title: event.target.value
      },
      {
       headers: {
        Authorization: 'Bearer ' + localStorage.getItem('user-token'),
       }
      })
   },
   changeWordDate(event, id, datetime) {
    const date = event.target.value + ' ' + datetime.split('T')[1].split('.')[0]
    axios.post(process.env.VUE_APP_HOST_ADRES + '/word/date',
      {
       id,
       createdAt: date
      },
      {
       headers: {
        Authorization: 'Bearer ' + localStorage.getItem('user-token'),
       }
      })
   },
   changeWordType(event, id) {
    axios.post(process.env.VUE_APP_HOST_ADRES + '/word/word-type',
      {
       id,
       type: event.target.value
      },
      {
       headers: {
        Authorization: 'Bearer ' + localStorage.getItem('user-token'),
       }
      })
   },
   getWords() {
    axios.get(process.env.VUE_APP_HOST_ADRES + '/words',
      {
       headers: {
        Authorization: 'Bearer ' + localStorage.getItem('user-token'),
       }
      })
      .then(response => (this.words = response.data.words))
   },
   getSQLDateToVueInputDate(video_date) {
    return video_date.split('T')[0]
   },
   getTypes: function () {
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/word/types',
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => (this.types = response.data.word_types))
   },
  }
 }
</script>

<style scoped>

</style>