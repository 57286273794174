<template>
    <section v-show="spotlightOn" class="spotlight">
        <main>
            <input
                    type="text"
                    ref="spotlight"
                    v-model="search"
                    v-on:input="getCatalogSearch"
                    v-on:keyup.up="goUpList"
                    v-on:keyup.down="goDownList"
                    v-on:keyup.enter="enterLink"
            />
        </main>
        <section class="results">
            <section class="result"
                     v-for="(result, index) in results.slice(slice, slice + 5)"
                     v-bind:class="{ selected: selection === index}"
                     v-bind:key="result.id"
                    @click="selectResult(result)">
                <p class="title">{{result.name}}</p>
                <p>{{result.category}}</p>
                <p class="media-type">{{result.media_type}}</p>
            </section>
        </section>
    </section>
</template>

<script>
 import axios from 'axios'
 export default {
  name: "spotlight",
  data() {
   return {
    search: null,
    results: [],
    selection: 0,
    slice: 0,
    keysPressed: [],
    spotlightOn:false
   }
  },
  mounted(){
   window.addEventListener('keydown', this.checkKey);
   window.addEventListener('keyup', this.removeKey);
  },
  beforeDestroy() {
   window.addEventListener('keydown', this.checkKey);
   window.removeEventListener('keyup', this.removeKey);
  },
  methods: {
   selectResult(result){
    this.spotlightOn = false;
    this.$router.push({name: result.media_type, params: { id: result.id }})
    return this.$router.go()
   },
   checkKey(){
    this.keysPressed[event.key] = true;
    this.activeSpotlight();
   },
   removeKey(){
    this.keysPressed = [];
   },
   displaySpotlight(){
    this.search = ''
    this.results = []
    this.spotlightOn = true;
    this.$nextTick(() => this.$refs.spotlight.focus())
   },
   removeSpotlight(){
    this.spotlightOn = false;
   },
   activeSpotlight(){
    if(this.keysPressed['Meta'] !== true || this.keysPressed['k'] !== true ){
     return;
    }

    if(this.spotlightOn === true){
     this.removeSpotlight();
     return;
    }

    this.displaySpotlight();
   },
   getCatalogSearch(){
    let self = this;
    if(self.search === ""){
     return;
    }
     axios.get(process.env.VUE_APP_HOST_ADRES + '/catalog/search/' + self.search,
       {
        headers: {
         Authorization: 'Bearer ' + localStorage.getItem('user-token'),
        }
       })
       .then(response => {
            this.results = response.data
            this.slice = 0;
            this.selection = 0;
       })
   },
   enterLink (){
    this.spotlightOn = false;
   const media = this.results[this.selection + this.slice];
    this.$router.push({name: media.media_type, params: { id: media.id }})
    return this.$router.go()
   },
   goUpList(){
    this.selection--
    if(this.selection < 0 && this.slice === 0 && this.results.length >= 5) {
     this.slice = this.results.length - 5;
     this.selection = 4;
    }
    if(this.selection < 0 && this.results.length >= 5) {
     this.selection = 0;
     this.slice--;
    }
    if(this.selection < 0 && this.results.length < 5) {
     this.selection = this.results.length - 1;
    }
   },
   goDownList(){
    if(this.selection + this.slice >= this.results.length - 1) {
     this.slice = 0;
     this.selection = 0;
     return;
    }
    if(this.selection + 1 >= 5){
     this.slice++;
     return;
    }
    this.selection++
   }
  }
 }
</script>

<style scoped lang="scss">
    .spotlight {
    input {
        background: #59b698;
        width: 95%;
        height: 60px;
        margin-top: 5px;
        border: none;
        font-size: 26px;
        color: #ffffff;
        font-weight: bold;
    }
    input:focus, select:focus{
        outline: none;
    }
    main {
        height: 80px;
    }
    .results {
        .result {
            height: 80px;
            width: 100%;
            background: #59b698;
            color: #ffffff;
            margin: 0;
            display: grid;
            grid-template-columns: 8fr 1fr 1fr;
            .title {
                text-align: left;
                padding: 0 15px;
                font-size: 20px;
            }
            p {
                font-weight: bold;
                align-self: center;
            }
            section {
                height: 60px;
            }
            .media-type {
                font-style: italic;
                font-weight: normal !important;
            }
        }
        .selected {
            background: #3a7763;
        }
        height: initial;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
    }
    position: fixed;
    width: 70%;
    min-width: 200px;
    background: #59b698;
    top: 20vh;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 5px;
    }
</style>