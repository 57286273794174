<template>
    <section>
        <section class="video-container">
          <video ref="video" class="video-view" v-if="video_src" oncontextmenu="return false;" @play="startPlay" @timeupdate="updateProcent" controls controlsList="nodownload">
            <source :src="video_src" type="video/mp4">
          </video>
          <div class="watermark" :style="{backgroundImage: `url('${createWatermarkSVG(userEmail)}')`}"></div>

          <vue-slider
                    v-model="speed"
                    v-bind="speedOptions"
                    @change="changeSpeed"
            />
            <section class="title">
                <h2>{{video_name}}</h2>
                <font-awesome-icon v-if="isView(video_id)" :icon="['fas', 'eye']"  />
            </section>
        </section>
        <router-link class="link" v-for="(word, index) in words" v-bind:key="index" :to="{name: 'word',  params: { id: word.id }}">{{word.title}}</router-link>
    </section>
</template>

<script>
 import axios from 'axios';
 import VueSlider from 'vue-slider-component'
 import 'vue-slider-component/theme/antd.css'

 export default {
  name: "WebVideo",
  components: {
   VueSlider
  },
  data() {
   return {
    speedOptions: {
     min: 0.5,
     max: 2.5,
     width: 100,
     interval: 0.1
    },
    speed: 1,
    video_id: null,
    video_name: null,
    video_filename: null,
    video_src: null,
    token: null,
    view_send: false,
    total_watch_time: 0,
    video_time: 0,
    view_id: null,
    procent_watched: 0,
    view_procent_watched: 0,
    polling: null,
    words: null,
    views: null,
   userEmail: '', // Add this line
   }
  },
  async mounted() {
    this.video_id = this.$route.params.id;
    await this.getAccess(this.video_id);
    await this.getUrl(this.video_id);
    await this.findOrCreateView(this.video_id);
    await this.getWords(this.video_id);
    await this.getViews();
    this.fetchUserEmail(); // Add this line
  },
  created() {
   this.pollData()
  },
  beforeDestroy() {
   clearInterval(this.polling)
  },
  methods: {
   changeSpeed(){
    this.$refs.video.playbackRate = this.speed;
   },
    createWatermarkSVG(email) {
      const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="200" height="100">
                   <text x="10" y="20" font-family="Arial" font-size="16" fill="rgba(255,255,255,0.5)">${email}</text>
                 </svg>`;
      return `data:image/svg+xml;base64,${btoa(svg)}`;
    },
  fetchUserEmail() {
    this.userEmail = localStorage.getItem('email') || 'Unknown';
  },
   isView(media_id){
    if(this.views === null){
     return;
    }

    const views = this.views.map(function (view){
     if(view.media_id === media_id){
      return true;
     }
    });

    return views.includes(true) || false;
   },
   pollData() {
    this.polling = setInterval(() => {
     this.sendProcent()
     this.sendView()
    }, 3000)
   },
   startPlay(event) {
    this.video_time = event.target.currentTime
   },
   sendView() {
    if (
      this.getCombinedProcent() < 60 ||
      this.view_send === true
    ) {
     return;
    }
    this.setVideoToWatched()
    this.view_send = true
   },
   updateProcent(event) {
    if ((event.target.currentTime - this.video_time) > 10) {
     return
    }

    if ((event.target.currentTime - this.video_time) < 0.5) {
     return
    }

    this.total_watch_time += event.target.currentTime - this.video_time;
    this.video_time = event.target.currentTime;
    this.procent_watched = this.total_watch_time / event.target.duration * 100;
   },
   getCombinedProcent() {
    if (typeof this.view_procent_watched === 'undefined') {
     return this.procent_watched
    }
    return this.view_procent_watched + this.procent_watched
   },
   sendProcent() {
    if (this.getCombinedProcent() > 100) {
     return;
    }

    const view = {
     view_id: this.view_id,
     procent_watched: this.getCombinedProcent()
    }

    axios
      .post(
        process.env.VUE_APP_HOST_ADRES + '/view/update/time',
        view,
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
   },
   getAccess(videoId) {
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/video/' + videoId + '/access',
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => {
       if(response.data === false){
        this.$router.push({name: 'catalog'})
       }
      })
   },
   getUrl(videoId) {
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/video/' + videoId,
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => {
       this.video_filename = response.data.videos[0].filename;
       this.video_name = response.data.videos[0].name;
       this.token = response.data.streamToken
       this.getStreamLink(this.token, this.video_filename);
      })
   },
   setVideoToWatched() {
    const self = this;
    axios
      .post(
        process.env.VUE_APP_HOST_ADRES + '/view/watched',
        {
         view_id: self.view_id,
        },
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
   },
   findOrCreateView(video_id) {
    axios
      .post(
        process.env.VUE_APP_HOST_ADRES + '/view/new',
        {'media_id':video_id},
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => {
       this.view_id = response.data.id
       this.view_procent_watched = response.data.procent_watched
      })
   },
   getStreamLink(tokenn, filename) {
    this.video_src = process.env.VUE_APP_HOST_ADRES + "/video/stream/" + tokenn + '/' + filename;
   },
   getWords(videoId) {
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/word-video/video/' + videoId,
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => {
       this.words = response.data
      })
   },
   getViews: function () {
    axios
      .get(process.env.VUE_APP_HOST_ADRES + '/views/user',
        {
         headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
         }
        })
      .then(response => (this.views = response.data))
   },
  }
 }
</script>

<style scoped lang="scss">
    .video-view {
        display: block;
        width: 100%;
        max-height: 500px;
        background: #000;
    }

    h2 {
        text-align: left;
    }

    #app {
        .link {
            text-align: left;
        }
    }

    .title {
        svg {
            align-self: center;
            justify-self: right;
        }
        display: grid;
        grid-template-columns: 4fr 1fr;
    }

    .video-container {
      position: relative;
    }

    .watermark {
      position: absolute;
      bottom: 20px;
      right: 20px;
      color: rgba(255, 255, 255, 0.75); // Adjust transparency as needed
      font-size: 14px;
      pointer-events: none;
      height: 100%;
      width: 100%;
      user-select: none;
      z-index: 10; // Ensure it's above the video
    }


    @media only screen and (max-width: 600px) {
        .video-view {
            width: 100%;
        }
    }
</style>